import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { ServiceInvoiceSpagettiProduct } from 'models';
import { ServiceInvoiceSpagettiProductService } from 'services';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';

@autoinject
export class ServiceInvoiceSpagettiProducts {
  protected spagettiProducts: ServiceInvoiceSpagettiProduct[] = [];

  protected headers: { title: string }[] = [];
  protected isCreating = false;
  protected editingId: number | undefined = 0;

  protected productForDismounted: ServiceInvoiceSpagettiProduct = {
    Id: undefined,
    IsForRopesMounted: false,
    IsForRopesDismounted: true,
    ProductId: undefined,
  };

  protected productForMounted: ServiceInvoiceSpagettiProduct = {
    Id: undefined,
    IsForRopesMounted: true,
    IsForRopesDismounted: false,
    ProductId: undefined,
  };

  protected form = {
    MountedProductId: undefined,
    DismountedProductId: undefined,
  };

  constructor(
    private t: I18N,
    private serviceInvoiceSpagettiProductService: ServiceInvoiceSpagettiProductService,
    private toaster: ToastService,
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('MountedProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('DismountedProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  protected attached() {
    void this.load();
  }

  protected async load() {
    await this.getSpagettiProducts();
  }

  private async saveProduct(product: ServiceInvoiceSpagettiProduct) {
    if (product.Id) {
      await this.serviceInvoiceSpagettiProductService.put(
        {
          Id: product.Id,
          ProductId: product.ProductId,
          IsForRopesDismounted: product.IsForRopesDismounted,
          IsForRopesMounted: product.IsForRopesMounted,
        } as ServiceInvoiceSpagettiProduct,
        product.Id
      );
    } else {
      await this.serviceInvoiceSpagettiProductService.post({
        ProductId: product.ProductId,
        IsForRopesDismounted: product.IsForRopesDismounted,
        IsForRopesMounted: product.IsForRopesMounted,
      } as ServiceInvoiceSpagettiProduct);
    }
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  protected async onSave() {
    try {
      if (!(await this.validate())) return;

      this.productForDismounted.ProductId = this.form.DismountedProductId;
      this.productForMounted.ProductId = this.form.MountedProductId;

      await Promise.all([this.saveProduct(this.productForDismounted), this.saveProduct(this.productForMounted)]);
      await this.getSpagettiProducts();
      this.toaster.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getSpagettiProducts() {
    this.spagettiProducts = await this.serviceInvoiceSpagettiProductService.getAllWithProduct();

    const productForDismounted = this.spagettiProducts.find((x) => x.IsForRopesDismounted);
    if (productForDismounted) {
      this.productForDismounted = productForDismounted;
      this.form.DismountedProductId = productForDismounted.ProductId;
    }

    const productForMounted = this.spagettiProducts.find((x) => x.IsForRopesMounted);
    if (productForMounted) {
      this.productForMounted = productForMounted;
      this.form.MountedProductId = productForMounted.ProductId;
    }
  }
}
