import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { RopeTypeService } from 'models';
import { RopeTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class RopeTypeEdit {
  private ropetype = new RopeTypeService();
  protected title = '';

  protected ready = false;
  protected id: number | undefined = undefined;

  constructor(
    private ropeTypeService: RopeTypeServiceService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  protected activate(params: { Id: number | 'new' }) {
    const id = +params?.Id;
    if (!isNaN(id)) {
      this.id = id;
    }
    void this.getRopeType();
  }

  protected async getRopeType() {
    try {
      if (this.id) {
        this.ropetype = await this.ropeTypeService.get(this.id);
        this.title = `${this.ropetype.Name}`;
      } else {
        this.title = `ropetype.new`;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.ready = true;
  }

  protected async create() {
    try {
      const type = await this.ropeTypeService.post(this.ropetype);
      this.eventAggregator.publish('ropeTypeListReset', 1);
      this.toastService.showSuccess('ropetype.created');
      this.router.navigateToRoute('service-rope-type-edit', { Id: type.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async update() {
    try {
      const type = await this.ropeTypeService.put(this.ropetype, this.ropetype.Id);
      this.eventAggregator.publish('ropeTypeListReset', 1);
      this.toastService.showSuccess('ropetype.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async save() {
    if (this.id) {
      await this.update();
    } else {
      await this.create();
    }
  }
}
