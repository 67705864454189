import { ServiceInvoiceMatrixBase } from './service-invoice-matrix-base';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { ServiceWasteProductMatrixService } from 'services';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { ServiceKiloWasteProductService } from 'services/service-kilo-waste-product-service';
import { ServiceKiloWasteProduct } from 'models';

@autoinject
export class WasteMatrixView extends ServiceInvoiceMatrixBase {
  private kiloWasteProducts: ServiceKiloWasteProduct[] = [];

  constructor(
    t: I18N,
    matrixService: ServiceWasteProductMatrixService,
    toaster: ToastService,
    errorService: ErrorService,
    private kiloWasteService: ServiceKiloWasteProductService
  ) {
    super(t, matrixService, toaster, errorService);

    void this.getKiloWasteProducts();
  }

  public async getKiloWasteProducts() {
    const products = await this.kiloWasteService.getAll();

    if (!products.length) {
      this.kiloWasteProducts.push(new ServiceKiloWasteProduct());
    } else {
      this.kiloWasteProducts = products;
    }
  }

  public async onSaveKiloWasteProducts() {
    try {
      for (const product of this.kiloWasteProducts) {
        if (!product.Id) {
          await this.kiloWasteService.post(product);
        } else {
          if (product.ProductId) {
            await this.kiloWasteService.put(product, product.Id);
          } else {
            await this.kiloWasteService.delete(product.Id);
          }
        }
      }
      this.toaster.showSuccess(this.t.tr('general.saved'));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
