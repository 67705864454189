import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { ProductService } from 'services/product-service';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { Models } from 'models/core';
import { ServiceInvoiceStandardProduct } from 'models';
import { ServiceInvoiceStandardProductService } from 'services/service-invoice-standard-product-service';

@autoinject
export class ServiceInvoiceStandardProducts {
  private products: Models.Product[] = [];

  protected standardProducts: ServiceInvoiceStandardProduct[] = [];

  protected headers: { title: string }[] = [];
  protected isCreating = false;
  protected editingId: number | undefined = 0;

  constructor(
    private t: I18N,
    private product: ProductService,
    private serviceInvoiceStandardProductService: ServiceInvoiceStandardProductService,
    private toaster: ToastService,
    private errorService: ErrorService
  ) {}

  protected attached() {
    this.createHeaders();
    void this.load();
  }

  protected async load() {
    await this.getStandardProducts();
    await this.getAllProducts();
  }

  private async getAllProducts() {
    this.products = await this.product.getAll('?$filter=ProductSource eq 1&$top=500&$orderby=Created desc');
    return this.products;
  }

  protected async onSave(form: ServiceInvoiceStandardProduct) {
    try {
      if (form.Id) {
        await this.serviceInvoiceStandardProductService.put(
          {
            Id: form.Id,
            ProductId: form.ProductId,
            IsForSpecialProduct: form.IsForSpecialProduct,
          } as ServiceInvoiceStandardProduct,
          form.Id
        );
      } else {
        await this.serviceInvoiceStandardProductService.post({
          ProductId: form.ProductId,
          IsForSpecialProduct: form.IsForSpecialProduct,
        } as ServiceInvoiceStandardProduct);
      }
      await this.getStandardProducts();
      this.toaster.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onDelete(id: number) {
    try {
      await this.serviceInvoiceStandardProductService.delete(id);
      this.toaster.showSuccess('general.deleted');
      await this.getStandardProducts();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getStandardProducts() {
    this.standardProducts = await this.serviceInvoiceStandardProductService.getAllWithProduct();
  }

  protected setIsEditing(id: number | undefined) {
    this.isCreating = false;
    this.editingId = id;
  }

  protected setIsCreating(isCreating: boolean) {
    this.editingId = undefined;
    this.isCreating = isCreating;
  }

  protected createHeaders() {
    this.headers = [
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.articleno') },
      { title: this.t.tr('general.isForSpecialProduct') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
