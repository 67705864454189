import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ProductService } from 'services/product-service';
import { ServiceStationLabourProductService } from 'services';
import { ServiceStationLabourProducts } from 'models';
import { ServiceStationService } from 'services/service-station-service';
import { ToastService } from 'services/toast-service';

export class TableServiceStationLabourProducts {
  Id?: number;

  ServiceStationId: number;
  ServiceStationName: string;
  ErpSystemName?: string;

  WashingProductName?: string;
  WashingProductId?: number;

  TestingProductName?: string;
  TestingProductId?: number;

  ReperationProductName?: string;
  ReperationProductId?: number;

  SewingMachineProductName?: string;
  SewingMachineProductId?: number;

  ImpregnationProductName?: string;
  ImpregnationProductId?: number;

  SpagettiProductName?: string;
  SpagettiProductId?: number;

  DeliveryProductName?: string;
  DeliveryProductId?: number;
}

@autoinject
export class PricingView {
  protected tableData: ServiceStationLabourProducts[];
  protected ready: boolean = false;
  protected activeTableRow: number;
  protected products: Models.Product[];

  constructor(
    private router: Router,
    private serviceStationService: ServiceStationService,
    private ServiceStationLabourProductService: ServiceStationLabourProductService,
    private productService: ProductService,
    private errorService: ErrorService,
    private toaster: ToastService
  ) {}

  private async getAllProducts() {
    this.products = await this.productService.getAll('?$filter=ProductSource eq 1&$top=2000&$orderby=Created desc');
  }

  protected table: TableServiceStationLabourProducts[] = [];

  protected createTableObject(serviceStation: Models.ServiceStation, sslp?: ServiceStationLabourProducts) {
    const newSslp = new TableServiceStationLabourProducts();
    newSslp.ServiceStationId = serviceStation.Id;
    newSslp.ServiceStationName = serviceStation.Name;
    newSslp.ErpSystemName = serviceStation.ErpSystem?.Title || '';
    if (sslp) {
      newSslp.Id = sslp.Id;
      newSslp.WashingProductId = sslp.WashingProductId;
      newSslp.TestingProductId = sslp.TestingProductId;
      newSslp.ReperationProductId = sslp.ReperationProductId;
      newSslp.SewingMachineProductId = sslp.SewingMachineProductId;
      newSslp.ImpregnationProductId = sslp.ImpregnationProductId;
      newSslp.SpagettiProductId = sslp.SpagettiProductId;
      newSslp.DeliveryProductId = sslp.DeliveryProductId;
    }

    return newSslp;
  }

  private async getAllServiceStations() {
    try {
      const serviceStations = await this.serviceStationService.getAll(
        '$orderby=Name&$filter=CanGenerateInvoiceData eq true&$expand=ErpSystem'
      );

      const sslp = await this.ServiceStationLabourProductService.getAll();
      const sslpMap = new Map(sslp.map((s) => [s.ServiceStationId, s]));
      // generate a table with all the service stations
      const table = [];
      for (const serviceStation of serviceStations) {
        table.push(this.createTableObject(serviceStation, sslpMap.get(serviceStation.Id)));
      }
      this.table = table;
      this.ready = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected createDtoModel(row: TableServiceStationLabourProducts) {
    const sslp = new ServiceStationLabourProducts();
    if (row.Id) {
      sslp.Id = row.Id;
    }
    sslp.ServiceStationId = row.ServiceStationId || null;
    sslp.WashingProductId = row.WashingProductId || null;
    sslp.TestingProductId = row.TestingProductId || null;
    sslp.ReperationProductId = row.ReperationProductId || null;
    sslp.SewingMachineProductId = row.SewingMachineProductId || null;
    sslp.ImpregnationProductId = row.ImpregnationProductId || null;
    sslp.SpagettiProductId = row.SpagettiProductId || null;
    sslp.DeliveryProductId = row.DeliveryProductId || null;
    return sslp;
  }

  protected async save() {
    try {
      const updates = this.table.map((row) => {
        const model = this.createDtoModel(row);
        if (model.Id) {
          return this.ServiceStationLabourProductService.put(model, model.Id);
        } else {
          return this.ServiceStationLabourProductService.post(model);
        }
      });

      await Promise.all(updates);
      this.toaster.showSuccess('general.saved');
      void this.getAllServiceStations();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected attached() {
    void this.getAllServiceStations();
    void this.getAllProducts();
  }

  protected gotoDetailView(id: number) {
    this.activeTableRow = id;
    this.router.navigateToRoute('service-station-main', { Id: id });
  }

  protected getProducts() {
    return JSON.parse(JSON.stringify(this.products));
  }

  protected setProductId(
    index: number,
    type: 'washing' | 'testing' | 'reperation' | 'impregnation' | 'spagetti' | 'delivery' | 'sewingMachine',
    data: { value: number | string }
  ) {
    const productId = Number(data.value);
    switch (type) {
      case 'washing':
        this.table[index].WashingProductId = productId;
        break;
      case 'testing':
        this.table[index].TestingProductId = productId;
        break;
      case 'reperation':
        this.table[index].ReperationProductId = productId;
        break;
      case 'sewingMachine':
        this.table[index].SewingMachineProductId = productId;
        break;
      case 'impregnation':
        this.table[index].ImpregnationProductId = productId;
        break;
      case 'spagetti':
        this.table[index].SpagettiProductId = productId;
        break;
      case 'delivery':
        this.table[index].DeliveryProductId = productId;
        break;
    }
    this.table = [...this.table];
  }
}
