import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { NettingTypeService } from 'models';
import { NettingTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class NettingTypeEdit {
  private nettingType = new NettingTypeService();
  protected title = '';

  protected ready = false;
  protected id: number | undefined = undefined;

  constructor(
    private nettingTypeService: NettingTypeServiceService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  protected activate(params: { Id: number | 'new' }) {
    const id = +params?.Id;
    if (!isNaN(id)) {
      this.id = id;
    }
    void this.getNettingType();
  }

  protected async getNettingType() {
    try {
      if (this.id) {
        this.nettingType = await this.nettingTypeService.get(this.id);
        this.title = `${this.nettingType.Name}`;
      } else {
        this.title = `nettingtype.new`;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.ready = true;
  }

  protected async create() {
    try {
      const type = await this.nettingTypeService.post(this.nettingType);
      this.eventAggregator.publish('nettingTypeListReset', 1);
      this.toastService.showSuccess('nettingtype.created');
      this.router.navigateToRoute('service-rope-type-detail', { Id: type.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async update() {
    try {
      await this.nettingTypeService.put(this.nettingType, this.nettingType.Id);
      this.eventAggregator.publish('nettingTypeListReset', 1);
      this.toastService.showSuccess('nettingtype.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async save() {
    if (this.id) {
      await this.update();
    } else {
      await this.create();
    }
  }
}
