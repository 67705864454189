import { I18N } from 'aurelia-i18n';
import { ServiceWashProductMatrixService, ServiceWasteProductMatrixService } from 'services';
import { ServiceWashProductMatrix, ServiceWasteProductMatrix } from 'models';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { MatrixSaveModel } from 'features/net-circumference-depth-product-create-edit';

type DepthProduct = {
  ProductId: string;
  ArticleNo: string;
  ArticleName: string;
  Id?: string;
};

type MatrixGrouped = {
  Circumference: number;
  0: DepthProduct;
  20: DepthProduct;
};

export class ServiceInvoiceMatrixBase {
  protected matrix: (ServiceWasteProductMatrix | ServiceWashProductMatrix)[] = [];
  protected matrixGrouped: MatrixGrouped[];
  protected products: Models.Product[] = [];

  protected headers = [];

  constructor(
    protected t: I18N,
    private matrixService: ServiceWashProductMatrixService | ServiceWasteProductMatrixService,
    protected toaster: ToastService,
    protected errorService: ErrorService
  ) {
    this.createHeaders();
  }

  public attached() {
    void this.getMatrix();
  }

  editingCircumference: number | undefined = undefined;
  setEditingCircumference(circumference: number) {
    this.isCreating = false;
    this.editingCircumference = circumference;
  }

  protected isCreating = false;
  setIsCreating(isCreating: boolean) {
    this.editingCircumference = undefined;
    this.isCreating = isCreating;
  }

  protected async getMatrix() {
    this.matrix = (await this.matrixService.getAll('?$expand=Product')) || [];
    this.groupByCircumference(this.matrix);
  }

  protected async circumferenceExists(circumference: number, ids?: number[]) {
    const existingCircumference = (await this.matrixService.get(
      `?$filter=Circumference eq ${circumference}`
    )) as unknown as ServiceWasteProductMatrix[];

    if (ids?.length && existingCircumference.some((x) => ids?.includes(x.Id))) return false;

    if (existingCircumference.length) {
      this.toaster.showWarning(
        this.t.tr('validation.ValueAlreadyExists', { value: this.t.tr('general.theCircumference') })
      );
      return true;
    }
    return false;
  }

  protected async onSave(data: MatrixSaveModel[]) {
    if (data.every((x) => !!x.Id)) {
      await this.doUpdate(data);
    } else if (data.every((x) => !x.Id)) {
      await this.doCreate(data);
    }
  }

  protected async doCreate(rules: MatrixSaveModel[]) {
    if (await this.circumferenceExists(rules[0].Circumference)) return;
    await Promise.all(rules.map((rule) => this.matrixService.post(rule as ServiceWashProductMatrix)));
    await this.onSaved('create');
  }

  protected async doUpdate(rules: MatrixSaveModel[]) {
    const ids = rules.map((r) => r.Id);
    if (await this.circumferenceExists(rules[0].Circumference, ids)) return;
    await Promise.all(rules.map((rule) => this.matrixService.put(rule as ServiceWashProductMatrix, rule.Id)));
    await this.onSaved('update');
  }

  protected async onSaved(type: 'create' | 'update') {
    await this.getMatrix();

    if (type === 'create') {
      this.setIsCreating(false);
      this.toaster.showSuccess(this.t.tr('general.created'));
    } else {
      this.setEditingCircumference(undefined);
      this.toaster.showSuccess(this.t.tr('general.updated'));
    }
  }

  async onDelete(circumference: number | string) {
    try {
      const rule = this.matrixGrouped.find((x) => x.Circumference === circumference);
      if (!rule) return;
      const from0Id = rule?.[0].Id;
      const from20Id = rule?.[20].Id;
      await Promise.all([this.matrixService.delete(from0Id), this.matrixService.delete(from20Id)]);
      this.toaster.showSuccess(this.t.tr('general.entryDeleted'));
      await this.getMatrix();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private groupByCircumference(matrix: ServiceWashProductMatrix[]) {
    const temp = matrix.reduce((acc, current) => {
      if (!acc[current.Circumference]) acc[current.Circumference] = { Circumference: current.Circumference };

      acc[current.Circumference][current.Depth] = {
        ProductId: current.ProductId,
        ArticleNo: current.Product.ArticleNo,
        ArticleName: current.Product.ExternalName,
        Id: current.Id,
      };

      return acc;
    }, {});

    this.matrixGrouped = Object.values(temp);
  }

  createHeaders() {
    this.headers = [
      { title: this.t.tr('net.circumference') },
      { title: this.t.tr('serviceInvoice.articleNumberFromXdepth', { meters: 0 }) },
      { title: this.t.tr('serviceInvoice.articleNumberFromAndIncludingXdepth', { meters: 20 }) },
      { title: this.t.tr('general.actions', { meters: 20 }) },
    ];
  }
}
