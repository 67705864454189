import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { LoopTypeService } from 'models';
import { LoopTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class LoopTypeEdit {
  private loopType = new LoopTypeService();
  protected title = '';

  protected ready = false;
  protected id: number | undefined = undefined;

  constructor(
    private loopTypeService: LoopTypeServiceService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  protected activate(params: { Id: number | 'new' }) {
    const id = +params?.Id;
    if (!isNaN(id)) {
      this.id = id;
    }
    void this.getRopeType();
  }

  protected async getRopeType() {
    try {
      if (this.id) {
        this.loopType = await this.loopTypeService.get(this.id);
        this.title = `${this.loopType.Name}`;
      } else {
        this.title = `looptype.new`;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.ready = true;
  }

  protected async create() {
    try {
      const type = await this.loopTypeService.post(this.loopType);
      this.toastService.showSuccess('looptype.created');
      this.router.navigateToRoute('service-loop-type-edit', { Id: type.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async update() {
    try {
      await this.loopTypeService.put(this.loopType, this.loopType.Id);
      this.toastService.showSuccess('looptype.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async save() {
    if (this.id) {
      await this.update();
    } else {
      await this.create();
    }

    this.eventAggregator.publish('loopTypeListReset', 1);
  }
}
