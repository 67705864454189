import { autoinject, bindable, observable } from 'aurelia-framework';
import { NettingTypeService } from 'models';
import { ErrorService } from 'services/error-service';
import { MaterialService } from 'services/material-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { ProductService } from 'services/product-service';
import { ThreadTypeService } from 'services/thread-type-service';

@autoinject
export class NettingTypeForm {
  @bindable
  @observable
  private nettingType: NettingTypeService;
  nettingTypeChanged(nettingType: NettingTypeService) {
    if (nettingType?.Id) {
      void this.setProductName(nettingType.ProductId);
    }
  }

  protected articleNo = '';

  constructor(
    private productService: ProductService,
    private errorService: ErrorService,
    protected materialService: MaterialService,
    protected threadTypeService: ThreadTypeService,
    protected meshSizeService: MeshSizeService,
    protected nylonTypeService: NylonTypeService
  ) {}

  protected setProductId(event: { detail: { value: string } }) {
    const productId = Number(event.detail.value);
    this.nettingType.ProductId = productId;
    void this.setProductName(productId);
  }

  private async setProductName(productId: number) {
    try {
      if (productId) {
        const product = await this.productService.get(productId);
        this.nettingType.Name = product.Name;
        this.articleNo = product.ArticleNo;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
