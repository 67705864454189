import { I18N } from 'aurelia-i18n';
import { autoinject, bindable } from 'aurelia-framework';
import { ServiceInvoiceWashWasteNetTypeProductService, NetTypeService } from 'services';
import { ProductService } from 'services/product-service';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { Models } from 'models/core';
import { ServiceInvoiceWashWasteNetTypeProduct } from 'models';

@autoinject
export class InvoiceWashWasteNettypeProduct {
  @bindable
  protected handleFor: 'wash' | 'waste';

  private products: Models.Product[] = [];

  protected allWashWasteNetTypeProducts: ServiceInvoiceWashWasteNetTypeProduct[] = [];
  protected washWasteNetTypeProductsForType: ServiceInvoiceWashWasteNetTypeProduct[] = [];

  protected headers: { title: string }[] = [];
  protected isCreating = false;

  protected netTypes: Models.NetType[] = [];
  protected editingId: number | undefined = 0;

  constructor(
    private t: I18N,
    private product: ProductService,
    private serviceInvoiceWashWasteNetTypeProductService: ServiceInvoiceWashWasteNetTypeProductService,
    private toaster: ToastService,
    private errorService: ErrorService,
    private netTypeService: NetTypeService
  ) {}

  protected attached() {
    this.createHeaders();
    void this.load();
  }

  protected async load() {
    await this.getWashWasteNetTypeProducts();
    await this.getAllProducts();
    await this.getNetTypes();
  }

  private async getAllProducts() {
    this.products = await this.product.getAll('?$filter=ProductSource eq 1&$top=500&$orderby=Created desc');
    return this.products;
  }

  private async getNetTypes() {
    this.netTypes = (await this.netTypeService.getAll()).filter((x) => !x.IsDeleted);
  }

  protected async onSave(form: ServiceInvoiceWashWasteNetTypeProduct) {
    try {
      const p = {
        WashProductId: form.WashProductId,
        WasteProductId: form.WasteProductId,
        NetTypeId: form.NetTypeId,
        Id: form.Id,
      } as ServiceInvoiceWashWasteNetTypeProduct;

      if (p.Id) {
        await this.serviceInvoiceWashWasteNetTypeProductService.put(p, p.Id);
      } else {
        await this.serviceInvoiceWashWasteNetTypeProductService.post(p);
      }
      await this.getWashWasteNetTypeProducts();
      this.toaster.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onDelete(id: number) {
    try {
      const pr = this.washWasteNetTypeProductsForType.find((x) => x.Id === id);
      const pro = {
        Id: pr.Id,
        WashProductId: pr.WashProductId,
        WasteProductId: pr.WasteProductId,
        NetTypeId: pr.NetTypeId,
      } as ServiceInvoiceWashWasteNetTypeProduct;
      if (this.handleFor === 'wash') {
        pro.WashProductId = null;
      } else {
        pro.WasteProductId = null;
      }

      await this.serviceInvoiceWashWasteNetTypeProductService.put(pro, pro.Id);
      this.toaster.showSuccess('general.deleted');
      await this.getWashWasteNetTypeProducts();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getWashWasteNetTypeProducts() {
    if (!this.handleFor) return;
    this.allWashWasteNetTypeProducts = await this.serviceInvoiceWashWasteNetTypeProductService.getAllWithProduct();

    this.washWasteNetTypeProductsForType = this.allWashWasteNetTypeProducts.filter((x) => {
      if (this.handleFor === 'wash') return !!x.WashProductId;
      return !!x.WasteProductId;
    });
  }

  protected setIsEditing(id: number | undefined) {
    this.isCreating = false;
    this.editingId = id;
  }

  protected setIsCreating(isCreating: boolean) {
    this.editingId = undefined;
    this.isCreating = isCreating;
  }

  protected createHeaders() {
    this.headers = [
      { title: this.t.tr('general.type') },
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.articleno') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
