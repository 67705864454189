import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { ServiceSpecialProductWashProductService } from 'services';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';
import { Models } from 'models/core';
import { ServiceSpecialProductWashProduct } from 'models';
import { SpecialProductWashProductModel } from 'features/special-product-wash-product-create-edit';
import { SpecialProductTypeService } from 'services/special-product-type-service';

@autoinject
export class SpecialProductWashing {
  private products: Models.Product[] = [];
  protected specialProductWashingProducts: ServiceSpecialProductWashProduct[] = [];

  protected headers: { title: string }[] = [];
  protected isCreating = false;

  protected specialProductTypes: Models.SpecialProductType[] = [];
  protected editingId: number | undefined = 0;

  constructor(
    private t: I18N,
    private serviceSpecialProductWashProductService: ServiceSpecialProductWashProductService,
    private toaster: ToastService,
    private errorService: ErrorService,
    private specialProductTypeService: SpecialProductTypeService
  ) {}

  protected attached() {
    this.createHeaders();
    void this.load();
  }

  protected async load() {
    await this.getSpecialProductWashingProducts();
    await this.getSpecialProductTypes();
  }

  private async getSpecialProductTypes() {
    const types = await this.specialProductTypeService.getAll();
    this.specialProductTypes = types.filter(
      (x) => !this.specialProductWashingProducts.find((sp) => sp.SpecialProductTypeId === x.Id)
    );
  }

  protected async onSave(form: SpecialProductWashProductModel) {
    try {
      if (form.Id) {
        await this.serviceSpecialProductWashProductService.put(
          {
            Id: form.Id,
            SpecialProductTypeId: form.SpecialProductTypeId,
            ProductId: form.ProductId,
          } as ServiceSpecialProductWashProduct,
          form.Id
        );
      } else {
        await this.serviceSpecialProductWashProductService.post({
          SpecialProductTypeId: form.SpecialProductTypeId,
          ProductId: form.ProductId,
        } as ServiceSpecialProductWashProduct);
      }
      await this.getSpecialProductWashingProducts();
      this.toaster.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onDelete(id: number) {
    try {
      await this.serviceSpecialProductWashProductService.delete(id);
      this.toaster.showSuccess('general.deleted');
      await this.getSpecialProductWashingProducts();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getSpecialProductWashingProducts() {
    this.specialProductWashingProducts = await this.serviceSpecialProductWashProductService.getAllWithProduct();
  }

  protected setIsEditing(id: number | undefined) {
    this.isCreating = false;
    this.editingId = id;
  }

  protected setIsCreating(isCreating: boolean) {
    this.editingId = undefined;
    this.isCreating = isCreating;
  }

  protected createHeaders() {
    this.headers = [
      { title: this.t.tr('general.type') },
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.articleno') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
