import { ServiceInvoiceMatrixBase } from './service-invoice-matrix-base';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { ServiceWashProductMatrixService } from 'services';
import { ToastService } from 'services/toast-service';
import { ErrorService } from 'services/error-service';

@autoinject
export class WashMatrixView extends ServiceInvoiceMatrixBase {
  constructor(
    t: I18N,
    matrixService: ServiceWashProductMatrixService,
    toaster: ToastService,
    errorService: ErrorService
  ) {
    super(t, matrixService, toaster, errorService);
  }
}
