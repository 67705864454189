import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { NettingTypeService } from 'models';
import { NettingTypeServiceService } from 'services';
import { ErrorService } from 'services/error-service';

@autoinject
export class NettingTypeView {
  protected tableData: NettingTypeService[];
  protected activeTableRow: number;
  protected ready: boolean = false;

  constructor(
    private nettingTypeService: NettingTypeServiceService,
    private errorService: ErrorService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {}

  protected activate(params: { Id: number }) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  protected attached() {
    this.eventAggregator.subscribe('nettingTypeListReset', () => {
      this.activeTableRow = null;
      return this.updateView();
    });
    void this.updateView();
  }

  private async updateView() {
    try {
      this.tableData = await this.nettingTypeService.getAll('?$orderby=Name&$expand=Product');
      this.ready = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected gotoDetailView(id: number) {
    this.activeTableRow = id;
    this.router.navigateToRoute('service-netting-type-edit', { Id: id });
  }
}
