import { autoinject, bindable, observable } from 'aurelia-framework';
import { PackagingProduct } from 'models';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class PackagingProductForm {
  @bindable
  @observable
  private packagingProduct: PackagingProduct;
  packagingProductChanged(packagingProduct: PackagingProduct) {
    if (packagingProduct?.Id) {
      void this.setProductName(packagingProduct.ProductId);
    }
  }

  protected articleNo = '';

  constructor(
    private productService: ProductService,
    private errorService: ErrorService
  ) {}

  protected setProductId(event: { detail: { value: string } }) {
    const productId = Number(event.detail.value);
    this.packagingProduct.ProductId = productId;
    void this.setProductName(productId);
  }

  private async setProductName(productId: number) {
    try {
      if (productId) {
        const product = await this.productService.get(productId);
        this.packagingProduct.Name = product.Name;
        this.articleNo = product.ArticleNo;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
